// extracted by mini-css-extract-plugin
export var BODY = "index-module--BODY--rS6OS";
export var BODY__newLayout = "index-module--BODY__newLayout--MxCEK";
export var BUTTON = "index-module--BUTTON--jZ5eY";
export var BUTTON__newLayout = "index-module--BUTTON__newLayout--UdFnn";
export var CAPTION = "index-module--CAPTION--DfXLh";
export var CAPTION__newLayout = "index-module--CAPTION__newLayout--YnLmM";
export var DESCRIPTION = "index-module--DESCRIPTION--itMkj";
export var DESCRIPTION__newLayout = "index-module--DESCRIPTION__newLayout--3XZ-F";
export var FOOTER = "index-module--FOOTER--Kfv5x";
export var FOOTERBOLD = "index-module--FOOTERBOLD--cX23w";
export var FOOTER__newLayout = "index-module--FOOTER__newLayout--3Fx0t";
export var HEADER1 = "index-module--HEADER1--Xc2o6";
export var HEADER1__newLayout = "index-module--HEADER1__newLayout--8sESD";
export var HEADER2 = "index-module--HEADER2--n3WZl";
export var HEADER2__newLayout = "index-module--HEADER2__newLayout--eeKBB";
export var HEADER3 = "index-module--HEADER3--yGDYr";
export var HEADER3__newLayout = "index-module--HEADER3__newLayout--efhCU";
export var HEADER4 = "index-module--HEADER4--i0utE";
export var HEADER4__newLayout = "index-module--HEADER4__newLayout--agSDm";
export var HERO = "index-module--HERO---2wK-";
export var HERO__newLayout = "index-module--HERO__newLayout--uhz7W";
export var QUOTE = "index-module--QUOTE--GGQ2H";
export var SUBHEADERDESC = "index-module--SUBHEADERDESC--ZiYZ6";
export var SUBHEADERDESCBOLD = "index-module--SUBHEADERDESCBOLD--SnHBr";
export var SUBHEADERDESC__newLayout = "index-module--SUBHEADERDESC__newLayout--nr788";
export var SUBTITLE = "index-module--SUBTITLE--POcrG";
export var SUBTITLE__newLayout = "index-module--SUBTITLE__newLayout--w0jm5";
export var center = "index-module--center--nMWdo";
export var container = "index-module--container--snUs9";
export var container__light = "index-module--container__light--s0XDV";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--fMOPZ";
export var tocLinks = "index-module--tocLinks--hjmmK";