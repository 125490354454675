import { Typography } from "@components/";
import classNames from "classnames";
import React from "react";
import * as styles from "./index.module.scss";

export type TableOfContentsProps = {
    contents: any[];
    theme?: "light" | "dark";
}

export default function TableOfContents({
    contents,
    theme="dark",
}: TableOfContentsProps): JSX.Element {
    return (
        <div className={classNames(styles.container, {
            [styles.container__light]: theme === "light"
        })}>
            <Typography variant="HEADER4" color={theme === "dark" ? "ui-01" : "text-dark"} style={{marginBottom: 24}}>
                Table of contents
            </Typography>
            <div className={styles.tocLinks}>
                {contents}
            </div>
        </div>
    )
}